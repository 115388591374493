import extender from 'assets/js/utilities/extender'
import _ from 'lodash'
import SB from 'assets/js/classes/SubmitBtn'
import ApiRoutes from 'assets/js/classes/ApiRoutes'
import { AxiosMethods } from 'assets/js/utilities/axios'
import collectFormData from 'assets/js/utilities/collectFormData'
import handleApiCall from 'assets/js/utilities/handleApiCall'
import { Actions as MemberButtonActions } from './MemberButton'

class Comment {
  // ++++++++++++++++++++++++++++++++++++++++++++++++++
  // GET COMMENT INDEX
  static getCommentIndex(task, commentId) {
    return task.comments.findIndex(comment => comment.id === commentId)
  }

  // ++++++++++++++++++++++++++++++++++++++++++++++++++
  // UPDATE COMMENT
  static async handleUpdateComment(e, modelContext, formControlContext, toastsContext) {
    e.preventDefault();

    const form = e.currentTarget?.form ?? e.currentTarget; // Need to get form before changing button state because currentTarget won't be available after elements in button change.

    // DISABLE INPUT
    await formControlContext.update(formControlContext.dispatch, { btnState: SB.LOADING });

    const response = await handleApiCall({
      apiMethod: AxiosMethods.PATCH, 
      apiRoute: ApiRoutes.TASK_COMMENT(modelContext.comment.id),
      callback: ({ comment }) => {
        modelContext.update(modelContext.dispatch, { task: _.set(modelContext.task, `comments.${modelContext.commentIndex}`, comment) });
      },
      formData: collectFormData(form),
      ...toastsContext
    });

    // DISPLAY RESPONSE
    await formControlContext.update(formControlContext.dispatch, { btnState: SB.RESPONSE, response });

    // ENABLE INPUT
    await formControlContext.update(formControlContext.dispatch, { btnState: SB.DEFAULT });
  }

  static async handleDeleteComment(e, modelContext, formControlContext, toastsContext) {
    e.preventDefault();

    const form = e.currentTarget?.form ?? e.currentTarget; // Need to get form before changing button state because currentTarget won't be available after elements in button change.

    // DISABLE INPUT
    await formControlContext.update(formControlContext.dispatch, { btnState: SB.LOADING });

    const response = await handleApiCall({
      apiMethod: AxiosMethods.DELETE, 
      apiRoute: ApiRoutes.TASK_COMMENT(modelContext.comment.id),
      callback: ({}) => {
        modelContext.task.comments.splice(modelContext.commentIndex, 1);

        modelContext.update(modelContext.dispatch, { task: _.set(modelContext.task, `comments`, modelContext.task.comments) });
      },
      ...toastsContext
    });

    // DISPLAY RESPONSE
    await formControlContext.update(formControlContext.dispatch, { btnState: SB.RESPONSE, response });

    // ENABLE INPUT
    await formControlContext.update(formControlContext.dispatch, { btnState: SB.DEFAULT });
  }
}

class Checklist {
  // ++++++++++++++++++++++++++++++++++++++++++++++++++
  // GET CHECKLIST INDEX
  static getChecklistIndex(task, checklistId) {
    return task.checklists.findIndex(checklist => checklist.id === checklistId)
  }

  // ++++++++++++++++++++++++++++++++++++++++++++++++++
  // GET CHECKLIST ID
  static getChecklistId(task, checklistIndex) {
    return task.checklists[checklistIndex].id;
  }

  // ++++++++++++++++++++++++++++++++++++++++++++++++++
  // GET CHECKLIST ITEM INDEX
  static getChecklistItemIndex(task, itemId) {
    const checklistIndex = task.checklists.findIndex(checklist => checklist.items.find(item => item.id === itemId));

    return task.checklists[checklistIndex]?.items?.findIndex(item => item.id === itemId)
  }

  // ++++++++++++++++++++++++++++++++++++++++++++++++++
  // GET CHECKLIST ITEM ID
  static getChecklistItemId(task, checklistIndex, checklistItemIndex) {
    return task.checklists[checklistIndex]?.items[checklistItemIndex]?.id;
  }

  // ++++++++++++++++++++++++++++++++++++++++++++++++++
  // ADD CHECKLIST ITEM
  static async handleAddChecklistItem(e, modelContext, formControlContext, toastsContext) {
    e.preventDefault();

    // DISABLE INPUT
    await formControlContext.update(formControlContext.dispatch, { btnState: SB.LOADING });

    const response = await handleApiCall({
      apiMethod: AxiosMethods.POST, 
      apiRoute: ApiRoutes.TASK_CHECKLIST_ITEMS(ModalTask.getChecklistId(modelContext.task, modelContext.checklistIndex)),
      callback: ({ item }) => {
        let checklist = modelContext.task.checklists[modelContext.checklistIndex];
        checklist.items.push(item);

        modelContext.update(modelContext.dispatch, { task: _.set(modelContext.task, `checklists.${modelContext.checklistIndex}`, checklist) })
      },
      ...toastsContext
    });

    // DISPLAY RESPONSE
    await formControlContext.update(formControlContext.dispatch, { btnState: SB.RESPONSE, response });

    // ENABLE INPUT
    await formControlContext.update(formControlContext.dispatch, { btnState: SB.DEFAULT });
  }

  // ++++++++++++++++++++++++++++++++++++++++++++++++++
  // CREATE CHECKLIST
  static async handleCreateChecklist(e, modelContext, formControlContext, toastsContext) {
    e.preventDefault();

    // DISABLE INPUT
    await formControlContext.update(formControlContext.dispatch, { btnState: SB.LOADING });

    const response = await handleApiCall({
      apiMethod: AxiosMethods.POST, 
      apiRoute: ApiRoutes.TASK_CHECKLISTS(modelContext.task.id),
      callback: ({ checklist }) => {
        modelContext.task.checklists.push(checklist);

        modelContext.update(modelContext.dispatch, { task: _.set(modelContext.task, `checklists`, modelContext.task.checklists) });
      },
      ...toastsContext
    });

    // DISPLAY RESPONSE
    await formControlContext.update(formControlContext.dispatch, { btnState: SB.RESPONSE, response });

    // ENABLE INPUT
    await formControlContext.update(formControlContext.dispatch, { btnState: SB.DEFAULT });
  }

  // ++++++++++++++++++++++++++++++++++++++++++++++++++
  // DELETE CHECKLIST
  static async handleDeleteChecklist(e, modelContext, formControlContext, toastsContext) {
    e.preventDefault();

    // DISABLE INPUT
    await formControlContext.update(formControlContext.dispatch, { btnState: SB.LOADING });

    const response = await handleApiCall({
      apiMethod: AxiosMethods.DELETE, 
      apiRoute: ApiRoutes.TASK_CHECKLIST(ModalTask.getChecklistId(modelContext.task, modelContext.checklistIndex)),
      callback: ({}) => {
        modelContext.task.checklists.splice(modelContext.checklistIndex, 1);

        modelContext.update(modelContext.dispatch, { task: _.set(modelContext.task, `checklists`, modelContext.task.checklists) });
      },
      ...toastsContext
    });

    // DISPLAY RESPONSE
    await formControlContext.update(formControlContext.dispatch, { btnState: SB.RESPONSE, response });

    // ENABLE INPUT
    await formControlContext.update(formControlContext.dispatch, { btnState: SB.DEFAULT });
  }

  // ++++++++++++++++++++++++++++++++++++++++++++++++++
  // DELETE CHECKLIST ITEM
  static async handleDeleteChecklistItem(e, modelContext, formControlContext, toastsContext) {
    e.preventDefault();

    // DISABLE INPUT
    await formControlContext.update(formControlContext.dispatch, { btnState: SB.LOADING });

    const response = await handleApiCall({
      apiMethod: AxiosMethods.DELETE, 
      apiRoute: ApiRoutes.TASK_CHECKLIST_ITEM(ModalTask.getChecklistItemId(modelContext.task, modelContext.checklistIndex, modelContext.itemIndex)),
      callback: ({}) => {
        let checklist = modelContext.task.checklists[modelContext.checklistIndex];
        checklist.items.splice(modelContext.itemIndex, 1);

        modelContext.update(modelContext.dispatch, { task: _.set(modelContext.task, `checklists.${modelContext.checklistIndex}`, checklist) })
      },
      ...toastsContext
    });

    // DISPLAY RESPONSE
    await formControlContext.update(formControlContext.dispatch, { btnState: SB.RESPONSE, response });

    // ENABLE INPUT
    await formControlContext.update(formControlContext.dispatch, { btnState: SB.DEFAULT });
  }
}

class Assignee {
  // ++++++++++++++++++++++++++++++++++++++++++++++++++
  // GET USER FROM ASSIGNEES IN TASK
  static isUserAssignee(task, user) {
    // FIND THE USER IN ASSIGNEES PROP
    return task.assignees.find(assignee => assignee.id === user.id) !== undefined
  }

  // ++++++++++++++++++++++++++++++++++++++++++++++++++
  // UPDATE ASSIGNEE
  static async handleUpdateAssignee(e, modelContext, formControlContext, toastsContext) {
    e.preventDefault();

    // DISABLE INPUT
    await formControlContext.update(formControlContext.dispatch, { btnState: SB.LOADING });

    const response = await handleApiCall({
      apiMethod: modelContext.action === MemberButtonActions.ADD ? AxiosMethods.POST : AxiosMethods.DELETE,
      apiRoute: ApiRoutes.TASK_USER(modelContext.task.id, modelContext.user.id),
      callback: ({ assignees }) => {
        modelContext.update(modelContext.dispatch, { task: _.set(modelContext.task, 'assignees', assignees) })
      },
      ...toastsContext
    });

    // DISPLAY RESPONSE
    await formControlContext.update(formControlContext.dispatch, { btnState: SB.RESPONSE, response });

    // ENABLE INPUT
    await formControlContext.update(formControlContext.dispatch, { btnState: SB.DEFAULT });

  }
}

class Archive {
  // ++++++++++++++++++++++++++++++++++++++++++++++++++
  // UPDATE TASK ARCHIVE STATUS
  static async handleArchive(e, modelContext, formControlContext, toastsContext) {
    e.preventDefault();

    // DISABLE INPUT
    await formControlContext.update(formControlContext.dispatch, { btnState: SB.LOADING });

    const response = await handleApiCall({
      apiMethod: AxiosMethods.PATCH, 
      apiRoute: ApiRoutes.TASK(modelContext.task.id),
      callback: ({ task }) => {
        modelContext.update(modelContext.dispatch, { task })
      },
      formData: collectFormData(e.target.form ?? e.target),
      ...toastsContext
    });

    // DISPLAY RESPONSE
    await formControlContext.update(formControlContext.dispatch, { btnState: SB.RESPONSE, response });

    // ENABLE INPUT
    await formControlContext.update(formControlContext.dispatch, { btnState: SB.DEFAULT });
  }
}

class Deadline {
  // ++++++++++++++++++++++++++++++++++++++++++++++++++
  // UPDATE TASK DEADLINE
  static async handleUpdateDeadline(e, modelContext, formControlContext, toastsContext) {
    e.preventDefault();

    // DISABLE INPUT
    await formControlContext.update(formControlContext.dispatch, { btnState: SB.LOADING });

    const response = await handleApiCall({
      apiMethod: AxiosMethods.PATCH, 
      apiRoute: ApiRoutes.TASK(modelContext.task.id),
      callback: ({ task }) => {
        modelContext.update(modelContext.dispatch, { task });
      },
      formData: collectFormData(e.target.form ?? e.target),
      ...toastsContext
    });

    // DISPLAY RESPONSE
    await formControlContext.update(formControlContext.dispatch, { btnState: SB.RESPONSE, response });

    // ENABLE INPUT
    await formControlContext.update(formControlContext.dispatch, { btnState: SB.DEFAULT });
  }
}

export default class ModalTask extends extender([Archive, Assignee, Checklist, Comment, Deadline]) {
  static FIRST_ITEM_IN_FORM = 0;
  static CHECKLIST_INDEX_INDEX = 1;
  static CHECKLIST_ITEM_INDEX_INDEX = 3;


  // ++++++++++++++++++++++++++++++++++++++++++++++++++
  // FORM ELEMENT FUNCTIONS
  // ++++++++++++++++++++++++++++++++++++++++++++++++++
  static blurInput(e, inputRef) {
    e.preventDefault();
    inputRef.current?.blur();
  }

  // ++++++++++++++++++++++++++++++++++++++++++++++++++
  // BLUR INPUT AND REVERT VALUE
  static handleEscapeKey(e, oldVal, setState) {  // DEPRECATED
    if (e.key === 'Escape') {  // Cancel edit
      if (oldVal === (e.target.form ?? e.target).elements.item(this.FIRST_ITEM_IN_FORM).value) {  // Value did not change, don't update state
        document.activeElement.blur();
      } else {
        setState(oldVal, () => document.activeElement.blur());
      }
    }
  }

  // ++++++++++++++++++++++++++++++++++++++++++++++++++
  // BLUR INPUT AND REVERT VALUE
  static handleInputEscape(e, inputRef, oldVal, setState) {
    if (e.key === 'Escape') {  // Cancel edit
      if (oldVal === inputRef.current.value) {  // Value did not change, don't update state
        document.activeElement.blur();
      } else {
        // setState(oldVal, () => document.activeElement.blur());
        setState(oldVal, () => inputRef.current.blur());
      }
    }
  }

  static async handleInputSubmit(e, inputRef, originalVal, setInput, modelContext, formControlContext, toastsContext) {
    if (! ModalTask.handleEmptySubmit(e, inputRef, originalVal, setInput)) {

      if (inputRef.current.isEqualNode(document.activeElement)) {
        ModalTask.blurInput(e, inputRef);
      } else { 
        const response = await ModalTask.handleSubmit(e, inputRef, { ...modelContext }, { ...formControlContext }, { ...toastsContext });
        if (response?.error) setInput(originalVal);
      }
      
    }
  }

  static handleKeyDown(e, inputRef, oldVal, setState, modelContext, formControlContext, toastsContext) {
    if (e.key === 'Enter') {
      ModalTask.handleInputSubmit(e, inputRef, oldVal, setState, modelContext, formControlContext, toastsContext);
    } else if (e.key === 'Escape') {
      ModalTask.handleInputEscape(e, inputRef, oldVal, setState);
    }
  }

  // ++++++++++++++++++++++++++++++++++++++++++++++++++
  // IF INPUT IS REQUIRED, REVERT IF SUBMITTED EMPTY
  static handleEmptySubmit(e, inputRef, oldVal, setState) {
    e.preventDefault();

    if(oldVal === undefined || setState === undefined) {
      return false;
    }

    if(!inputRef?.current) {  // This is to accommodate being unable to attach ref to phone input. Is there a better way?
      inputRef = {
        current: (e.target.form ?? e.target).elements.item(this.FIRST_ITEM_IN_FORM),
      }
    }
    
    if (inputRef.current.required && inputRef.current.value.trim() === '') {  // Revert value
      setState(oldVal, () => document.activeElement.blur());
      return true;
    } else {
      return false;
    }
  }
  
  // ++++++++++++++++++++++++++++++++++++++++++++++++++
  // SUBMIT FORM TO API
  // static handleInputSubmit = async (e, context, { stateUpdateFormControl, formControlDispatch }, { toasts, toastsDispatch }) => {  // TODO: move to ModalTask.js  // DEPRECATED
  //   console.log({e});
  //   e.preventDefault();

  //   // DISABLE INPUT
  //   await stateUpdateFormControl(formControlDispatch, { disabled: true });

  //   // BLUR ELEMENT -- NOTE: DONT NEED TO BLUR IF INPUT IS DISABLED
  //   // document.activeElement.blur();  // e.target.elements?.item(this.FIRST_ITEM_IN_FORM).blur();

  //   const inputName  = (e.target.form ?? e.target).elements.item(this.FIRST_ITEM_IN_FORM).name;
  //   const inputValue = (e.target.form ?? e.target).elements.item(this.FIRST_ITEM_IN_FORM).value;
  //   console.log({ inputName, inputValue })
  //   if (_.has(context.task, inputName) && inputValue !== _.get(context.task, inputName)) {  // If no difference
  //     // MAKE API CALL
  //     switch (inputName.split('.').shift()) {
  //       case 'client':
  //         var apiRoute = ApiRoutes.TASK_CLIENT(context.task?.id);
  //         break;
  //       case 'checklists':
  //         const checklistIndex = inputName.split('.').at(this.CHECKLIST_INDEX_INDEX);
  //         const checklistId = this.getChecklistId(context.task, checklistIndex);
  //         const checklistItemIndex = inputName.split('.').at(this.CHECKLIST_ITEM_INDEX_INDEX);
  //         const checklistItemId = this.getChecklistItemId(context.task, checklistIndex, checklistItemIndex);
  //         if (checklistItemIndex) {
  //           var apiRoute = ApiRoutes.TASK_CHECKLIST_ITEM(checklistItemId);
  //         } else {
  //           var apiRoute = ApiRoutes.TASK_CHECKLIST(checklistId);
  //         }
  //         break;
  //       default:
  //         var apiRoute = ApiRoutes.TASK(context.task?.id);
  //     }
  //     const response = await this.update(apiRoute, collectFormData(e.target.form ?? e.target), { toasts, toastsDispatch });
  //     console.log({response})

  //     if (response?.error) {
  //       await stateUpdateFormControl(formControlDispatch, { disabled: false });  // ENABLE INPUT
  //       return;  // CANCEL REMAINDER
  //     }
  //   }

  //   // UPDATE TASK OBJECT
  //   _.set(context.task, inputName, inputValue);

  //   // UPDATE TASK CONTEXT
  //   context.update(context.dispatch, { task: context.task });

  //   // ENABLE INPUT
  //   await stateUpdateFormControl(formControlDispatch, { disabled: false });
  // }

  // ++++++++++++++++++++++++++++++++++++++++++++++++++
  // SUBMIT FORM TO API
  static handleSubmit = async (e, inputRef, context, formControl, { toasts, toastsDispatch }) => {
    console.log({e});
    e.preventDefault();

    // DISABLE INPUT
    await formControl.update(formControl.dispatch, { disabled: true });

    const inputName = inputRef.current.name;
    const inputValue = inputRef.current[inputRef.current.type === 'checkbox' ? 'checked' : 'value'];

    if (_.has(context.task, inputName) && inputValue !== _.get(context.task, inputName)) {  // If no difference
      // MAKE API CALL
      switch (inputName.split('.').shift()) {
        case 'client':
          var apiRoute = ApiRoutes.TASK_CLIENT(context.task?.id);
          break;
        case 'checklists':
          const checklistIndex = inputName.split('.').at(this.CHECKLIST_INDEX_INDEX);
          const checklistId = this.getChecklistId(context.task, checklistIndex);
          const checklistItemIndex = inputName.split('.').at(this.CHECKLIST_ITEM_INDEX_INDEX);
          const checklistItemId = this.getChecklistItemId(context.task, checklistIndex, checklistItemIndex);
          if (checklistItemIndex) {
            var apiRoute = ApiRoutes.TASK_CHECKLIST_ITEM(checklistItemId);
          } else {
            var apiRoute = ApiRoutes.TASK_CHECKLIST(checklistId);
          }
          break;
        default:
          var apiRoute = ApiRoutes.TASK(context.task?.id);
      }
      var response = await this.update(apiRoute, collectFormData(e.target.form ?? e.target), { toasts, toastsDispatch });
      console.log({response})

      if (response?.error) {
        formControl.update(formControl.dispatch, { disabled: false });  // ENABLE INPUT
        return response;  // CANCEL REMAINDER
      }
    }

    // UPDATE TASK OBJECT
    _.set(context.task, inputName, inputValue);

    // UPDATE TASK CONTEXT
    context.update(context.dispatch, { task: context.task });

    // ENABLE INPUT
    await formControl.update(formControl.dispatch, { disabled: false });

    return response;
  }

  // ++++++++++++++++++++++++++++++++++++++++++++++++++
  // API HANDLER
  static update = async (apiRoute, formData, { toasts, toastsDispatch }) => {
    return await handleApiCall({
      apiMethod: AxiosMethods.PATCH, 
      apiRoute,
      formData,
      toasts,
      toastsDispatch,
    });
  }
}