import { useEffect, useRef, useState } from 'react'
import { Form } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import ApiRoutes from 'assets/js/classes/ApiRoutes'
import Submitter from 'assets/js/classes/Submitter'
import { AxiosMethods } from 'assets/js/utilities/axios'
import FormSubmitter from 'assets/js/classes/FormSubmitter'
import SubmitBtn from 'components/forms/SubmitBtn'
import ValidationAlert from 'components/ui/ValidationAlert'
import { stateUpdateDashboard, useDashboardDispatch, useDashboardState } from 'contexts/DashboardContext'
// import { stateUpdateBuckets, useDynamicBoardDispatch, useDynamicBoardState } from 'contexts/DynamicBoardContext'
import { stateUpdateSubmitBtn, useSubmitBtnDispatch, useSubmitBtnState } from 'contexts/SubmitBtnContext'
import { updateToasts, useToastsDispatch, useToastsState } from 'contexts/ToastsContext'
import projectBuckets from 'data/projectBuckets.json'
import ClientDetails from './components/ClientDetails'
import Deadline from './components/Deadline'
import Notes from './components/Notes'
import ProjectsDropdown from './components/ProjectsDropdown'
import '../../css/new-task-modal.css'
import FrontendRoutes from 'assets/js/classes/FrontendRoutes'

export default function NewTaskForm() {
  // CONTEXT PARAMS - DASHBOARD
  const { buckets, bucketsRef, scrollSnap } = useDashboardState();
  const dashboardDispatch = useDashboardDispatch();

  // CONTEXT PARAMS - TOASTS
  const { toasts } = useToastsState();
  const toastsDispatch = useToastsDispatch();
  
  // CONTEXT PARAMS - SUBMIT BUTTON
  const { btnState, response } = useSubmitBtnState();
  const submitBtnDispatch = useSubmitBtnDispatch();

  // STATE PARAMS
  const [formValidated, setFormValidated] = useState(false);
  const [showValidationAlert, setShowValidationAlert] = useState(false);
  const [validationMessage, setValidationMessage] = useState(null);

  // FORM PARAMS
  const [loading, setLoading] = useState(true);
  const [invalidFeedback, setInvalidFeedback] = useState({});
  const bucketId = useRef(null);

  // REACT ROUTER DOM
  const params = useParams();
  const navigate = useNavigate();

  // FORM SUBMIT
  const handleSubmit__deprecated = async e => {
    const formSubmitter = new FormSubmitter(e, {}, { url: '/' });

    await formSubmitter.validate({setFormValidated, setShowValidationAlert, setValidationMessage});

    // if(formSubmitter.response !== undefined) {
    //   // THROW TOAST TO CONFIRM SAVE
    //   toasts.unshift(new Toast(
    //     'Changes Saved', 
    //     `Created a new task #${formSubmitter.response?.data?.task?.id}.`,
    //     { autohide: true }
    //   ));
    //   updateToasts(toastsDispatch, { toasts });
      
    //   // REDIRECT TO TASK MODAL
    //   if(formSubmitter.response?.data?.task?.id) navigate(`/projects/task/${formSubmitter.response.data.task.id}`)
    // }
  }

  const handleSubmit = async e => {
    console.log(e.target)
    // RESET INVALID FEEDBACK
    setInvalidFeedback({});

    const submitter = new Submitter(e, 
      { stateUpdateSubmitBtn, submitBtnDispatch }, 
      { method: AxiosMethods.POST, route: ApiRoutes.BUCKETS_TASKS(e.target.elements.bucket.value) });

    await submitter.validate({setFormValidated, setShowValidationAlert, setValidationMessage, setInvalidFeedback});

    if(submitter.success) {
      // REDIRECT TO TASK MODAL
      if(submitter.response.data?.task?.id) navigate(FrontendRoutes.PROJECTS_TASK(submitter.response.data?.task?.id));
    }

    document.activeElement.blur()
  }

  return (
    <Form noValidate validated={formValidated} autoComplete="off" onSubmit={handleSubmit}>

      <ClientDetails validated={formValidated} />
      <Deadline />
      <ProjectsDropdown buckets={buckets} defaultBucket={buckets?.find(bucket => bucket.slug === params.project)} />
      <Notes />

      <ValidationAlert 
        showValidationAlert={showValidationAlert} 
        validationMessage={validationMessage} />

      <SubmitBtn 
        disabled={false && loading}
        icon={['fas', 'arrow-right']} 
        text="Submit"
        state={btnState}
        response={response} />

    </Form>
  )
}